.embedded-heatmap-parent {
  display: contents;
}

body:has(.embedded-heatmap-parent) {
  background: transparent;
}

.embedded-heatmap-permissions-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  width: 100%;
}

.embedded-heatmap-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  width: 100%;
  text-align: center;
}