.autosuggest-title {
    font-weight: "bold";
    margin: 0
}

.autosuggest-subtitle {
    color: grey;
    font-style: "normal";
    margin: 0;
}

.autosuggest-distance {
    color: grey;
    font-style: italic;
    margin: 0;
}