/* We only want tailwind for the utility classes */
@tailwind utilities;
@import "ol/ol.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";


/********************************************
LAYOUT
********************************************/
.row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex: 1;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.pretty-wrap {
  /* Pretty isn't widely supported, so balance is a fallback */
  text-wrap: balance;
  text-wrap: pretty;
}

/********************************************
MANTINE BASED
********************************************/
.inline-image {
  display: inline;

  >figure {
    display: inline;

    >div {
      display: inline;

      >img {
        display: inline;
      }
    }
  }
}

.respect-whitespace {
  white-space: pre-wrap;
}

/* https://github.com/mantinedev/mantine/issues/1229 */
.mantine-Slider-markWrapper {
  &:first-of-type>.mantine-Slider-markLabel {
    transform: unset;
    position: absolute;
    left: -8px;
  }

  &:last-of-type>.mantine-Slider-markLabel {
    transform: unset;
    position: absolute;
    right: 0;
  }
}

/********************************************
CONTROLS
********************************************/
.button-panel {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}