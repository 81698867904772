.landing-page-parent {
  flex: 1;
  flex-direction: column;

  .solid-landing-page-background {
    background: #59a4f4;
    height: 100%;
    width: 100%;
    z-index: -3;
    position: fixed;
  }

  .hacker-news-badge {
    height: 0;
    overflow: visible;
    padding-top: 8px;
    padding-right: 8px;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
  }


  .landing-page-glowing-text {
    text-shadow: 0 0 40px rgba(255, 255, 255, 0.471);
  }

  .landing-page-city-background {
    position: fixed;
    z-index: -2;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
    opacity: 0;
    transition: opacity ease-in 1s;
  }

  .landing-page-main-content {
    padding-bottom: 16px;
  }
}