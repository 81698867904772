.split-button-button {
    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.split-button-menuControl {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    margin-left: 1px;
}