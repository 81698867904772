.supported-site-logo {
  height: 100%;
  flex: 1;

  img {
    filter: grayscale(100%) contrast(0);
    object-fit: contain;
    height: 100%;
    width: 100%
  }

}